@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";

* {
  font-family: 'Regular';
}

@font-face {
  font-family: 'Light';
  src: url('/src/assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Regular';
  src: url('/src/assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Medium';
  src: url('/src/assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bold';
  src: url('/src/assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Super-Bold';
  src: url('/src/assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.auto-generator {
  margin: 50px 0;
}

.link {
  font-family: 'Regular' !important;
}

.clear {
  top: 8px;
  right: 8px;
  color: #878484;
  background-color: #fff;

  font-size: 13px;
  cursor: pointer;
  padding: 5px;
}

.get-description span {
  font-family: 'Bold';
}

.keywords .keyword-item {
  font-size: 13px;
  border: 1px solid #ccc;
  color: #444444d6;
  padding: 3px 6px;
  border-radius: 5px;
}

.description-area {
  color: #444;
  font-size: 14px;
  line-height: 23px;
}

.circle-element {
  border-radius: 50%;
}

textarea {
  resize: vertical !important;
  font-size: 14px !important;
}

label {
  font-size: 14px;
  font-family: 'Bold' !important;
  color: #444;
}


label.special {
  margin-top: 10px;
}

.links {
  margin-top: 50px;
}

.custom-panel {
  max-width: 500px;
}

.custom-panel a {
  color: #337ab7;
  text-decoration: none;
}

.custom-panel .p-panel-title {
  font-size: 14px !important;
  font-family: 'Bold';
  line-height: 18px;
}

.custom-panel .p-panel-content p {
  font-size: 14px !important;
  line-height: 22px;
}

.custom-panel .p-panel-content p b {
  font-size: 14px !important;
  line-height: 22px;
  font-family: 'Bold';
  margin-right: 5px;
}

.p-panel .p-panel-header {
  padding: 0.8rem;
}

.p-panel .p-panel-content {
  padding: 0.8rem;
}

.logo-title {
  max-width: 350px;
  font-family: "Super-Bold", sans-serif;
  text-transform: uppercase;
  background: #ef4f4f;
  background: linear-gradient(45deg, #ef4f4f 33%, #65C0FF 66%);
  background: -o-linear-gradient(45deg, #ef4f4f 33%, #65C0FF 66%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #0b2349;
  display: block;
  font-size: 50px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
}